import clsx from 'clsx';
import BulletList from 'components/common/BulletList';
import Image from 'components/common/Image';
import { BasicImage, ProductCardProps } from 'interfaces/cms/content';
import Link from 'next/link';
import sanitizeHtml from 'sanitize-html';

export default function ProductCard({
  product,
  maxCoverageImages = 0,
}: {
  product: ProductCardProps;
  maxCoverageImages?: number;
}) {
  return (
    <div className="border bg-white shadow-md flex flex-col p-1 w-full h-full justify-between">
      {product.highlight && (
        <div
          className="text-black text-center py-2 text-base font-medium"
          style={{
            backgroundColor: product.highlight.backgroundColor,
          }}
        >
          {product.highlight.text}
        </div>
      )}

      <Image
        className="self-center"
        src={product.image.desktopImage.url}
        alt={product.image.desktopImage.alt}
        width={280}
        height={130}
      />

      <span
        className="text-sm font-semibold text-center line-clamp-2"
        style={{
          color: product.productName.color,
          backgroundColor: product.productName.backgroundColor,
        }}
      >
        {product.productName.text}
      </span>

      {product.label && (
        <span
          className="text-sm text-center h-12 inline-flex items-center justify-center mx-4"
          style={{
            color: product.label.color,
            backgroundColor: product.label.backgroundColor,
          }}
        >
          <p
            className="line-clamp-2"
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(product.label.text),
            }}
          ></p>
        </span>
      )}

      <div className="overflow-hidden mt-2 mx-4 grow">
        <BulletList items={product.usp_items} />
      </div>

      <div className="mt-2 mx-4 grid grid-cols-3 gap-1">
        {product.coverageImages.map((imageLink, index) => (
          <CoverageVisualImage imageLink={imageLink} key={index} />
        ))}
        {Array.from({
          length: maxCoverageImages - product.coverageImages.length,
        }).map((_, index) => (
          <div key={index} className="aspect-square"></div>
        ))}
      </div>

      <div className="flex flex-col gap-2 m-4">
        {product.cta.map((cta, index) => (
          <Link
            key={index}
            href={cta.url}
            className="text-base rounded py-2 text-center"
            style={{
              backgroundColor: cta.backgroundColor,
              color: cta.color,
            }}
          >
            {cta.title}
          </Link>
        ))}
      </div>
    </div>
  );
}

function CoverageVisualImage({ imageLink }: { imageLink: BasicImage }) {
  return (
    <div
      className={clsx(
        'border aspect-square flex items-center justify-center',
        imageLink.link
          ? 'cursor-pointer hover:border-gray-300 hover:shadow'
          : 'cursor-default'
      )}
      onClick={() => imageLink.link && window.open(imageLink.link, '_blank')}
    >
      <Image
        src={imageLink.image.url}
        alt={imageLink.image.alt}
        width={imageLink.image.width}
        height={imageLink.image.height}
      />
    </div>
  );
}
