import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';

import { isAsiadirect, rem } from 'lib';
import { useTranslation } from 'next-i18next';

function LogoImage({ src, divWidth, divHeight, width, height }) {
  return (
    <div
      className="relative mt-[6.25rem] mb-[1.875rem]"
      style={{ width: divWidth, height: divHeight }}
    >
      <Image src={src} width={width} height={height} alt="page not found" />
    </div>
  );
}

function PageNotFound() {
  const { t } = useTranslation(['common']);

  return (
    <div
      className={clsx(
        'flex flex-col justify-center place-items-center',
        'pb-[6.25rem] md:pb-0 overflow-hidden'
      )}
    >
      {isAsiadirect() ? (
        <LogoImage
          divWidth={rem(530)}
          divHeight={rem(273)}
          width={530}
          height={273}
          src="/images/asiadirect/page-not-found.png"
        />
      ) : (
        <LogoImage
          divWidth={rem(424)}
          divHeight={rem(336)}
          width={848}
          height={672}
          src="/images/page-not-found.png"
        />
      )}

      <h2 className="text-primaryColor font-bold mb-[0.625rem]">
        {t('title_404')}
      </h2>
      <span className="text-textColor text-center mb-[2.5rem]">
        {t('message_404')}
      </span>

      <Link href="/" passHref>
        <button
          type="button"
          className={clsx(
            'bg-primaryColor hover:bg-buttonPrimaryBgHover',
            'text-white px-[2.5rem] py-[0.9375rem] font-bold',
            'uppercase rounded-[0.625rem]'
          )}
        >
          {t('backToHomepage_404')}
        </button>
      </Link>
    </div>
  );
}

export default PageNotFound;
