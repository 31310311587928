import { useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import DynamicContent from 'components/DynamicContent';
import PageNotFound from 'components/PageNotFound';

import { getWebsiteUrl, isAsiadirect } from 'lib';
import { Hreflang, Page } from 'interfaces/cms/page';

export interface PageProps {
  page: Page;
  previewMode?: boolean;
  hreflangs?: Hreflang[];
}

function PageComponent({
  page,
  hreflangs,
  children,
}: PageProps & { children?: React.ReactNode }) {
  const mainClasses = 'w-full p-0 block flex-1 mr-auto my-0';

  useEffect(() => {
    if (page && page.bgPattern) {
      document?.querySelector('body')?.classList.add('bg-pattern');
    } else {
      document?.querySelector('body')?.classList.remove('bg-pattern');
    }
  }, [page]);

  if (page?.slug === 'page-not-found') {
    return (
      <>
        <FaviconHead hreflangs={hreflangs} />
        <main className={mainClasses}>
          <PageNotFound />
        </main>
      </>
    );
  }

  return (
    <>
      <FaviconHead hreflangs={hreflangs} />
      {page?.content && page?.content?.length > 0 && (
        <main className={mainClasses}>
          <DynamicContent content={page.content} />
          {children}
        </main>
      )}
    </>
  );
}
const iconPath = () => (isAsiadirect() ? '/images/asiadirect' : '');

const FaviconHead = ({ hreflangs }: { hreflangs: Hreflang[] }) => {
  const { asPath } = useRouter();

  return (
    <Head>
      <link
        rel="icon"
        sizes="64x64"
        href={`${iconPath()}/favicon.ico`}
        type="image/x-icon"
      />
      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href={`${iconPath()}/apple-touch-icon-57x57.png`}
        type="image/png"
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href={`${iconPath()}/apple-touch-icon-60x60.png`}
        type="image/png"
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href={`${iconPath()}/apple-touch-icon-72x72.png`}
        type="image/png"
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href={`${iconPath()}/apple-touch-icon-76x76.png`}
        type="image/png"
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href={`${iconPath()}/apple-touch-icon-114x114.png`}
        type="image/png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href={`${iconPath()}/apple-touch-icon-120x120.png`}
        type="image/png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href={`${iconPath()}/apple-touch-icon-144x144.png`}
        type="image/png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href={`${iconPath()}/apple-touch-icon-152x152.png`}
        type="image/png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${iconPath()}/apple-touch-icon-180x180.png`}
        type="image/png"
      />
      <link
        rel="icon"
        type="image/png"
        href={`${iconPath()}/favicon-32x32.png`}
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href={`${iconPath()}/android-chrome-192x192.png`}
        sizes="192x192"
      />
      <link
        rel="icon"
        type="image/png"
        href={`${iconPath()}/favicon-96x96.png`}
        sizes="96x96"
      />
      <link
        rel="icon"
        type="image/png"
        href={`${iconPath()}/favicon-16x16.png`}
        sizes="16x16"
      />
      <link
        rel="manifest"
        href={isAsiadirect() ? '/manifest-asiadirect.json' : '/manifest.json'}
      />
      <link rel="alternate" hrefLang="x-default" href={getWebsiteUrl(asPath)} />
      {hreflangs?.map((hreflang) => (
        <link
          key={hreflang.id}
          rel="alternate"
          hrefLang={hreflang.lang}
          href={hreflang.url}
        />
      ))}
    </Head>
  );
};
export default PageComponent;
